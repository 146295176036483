@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom scrollbar width and color for larger screens */
@media (min-width: 768px) {
  .scrollbar-wider::-webkit-scrollbar {
    width: 12px; /* Adjust scrollbar width for larger screens */
  }

  .scrollbar-wider::-webkit-scrollbar-thumb {
    background-color: #ab79f3; /* Adjust thumb color for larger screens */
  }
}

/* Custom scrollbar width and color for all screens */
.scrollbar-thin::-webkit-scrollbar {
  width: 6px; /* Adjust scrollbar width for all screens */
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: #dcdcdc; /* Adjust thumb color for all screens */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-type: none;
}

li {
  list-style-type: none;
}

